<template>
  <div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  props: [
    'redirectPage'
  ],
  created () {
    this.$router.push({name: this.redirectPage})
  },
}
</script>
